import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { authHeader, baseUrlConfig } from "../../helpers/http"

export const downloadCV = createAsyncThunk("downloadCV", async ({ id, type }) => {
  const params = () => {
    if (type === "id") {
      return { cvInd: true }
    } else {
      return { cvJp: true }
    }
  }
  const response = await axios.get(`${baseUrlConfig.baseUrl}seeker/profile/${id}/export`, {
    headers: authHeader(),
    responseType: "arraybuffer",
    params: params(),
  })
  return response.data
})

const initialState = {
  file: null,
  loading: false,
  error: null,
}

const seekerCVSlice = createSlice({
  name: "cv_file",
  initialState,
  reducers: {
    reset: (state) => {
      state.value = initialState
    },
  },
  extraReducers: {
    [downloadCV.pending]: (state) => {
      state.loading = true
    },
    [downloadCV.fulfilled]: (state, action) => {
      state.loading = false
      state.file = action.payload
    },
    [downloadCV.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error
    },
  },
})

export const { reset } = seekerCVSlice.actions

export default seekerCVSlice.reducer
