import { lazy } from "react"
import HelpLayout from "@views/Help"
const Home = lazy(() => import("@views/Help/pages/Home"))
const DeleteAccount = lazy(() => import("@views/Help/pages/DeleteAccount"))

export default [
  {
    path: "",
    element: <HelpLayout />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "delete-account",
        element: <DeleteAccount />,
      },
    ],
  },
]
