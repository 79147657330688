// ** React Imports
import { Fragment, lazy } from "react"
import { Navigate } from "react-router-dom"
// ** Layouts
import BlankLayout from "@layouts/BlankLayout"
import LandingLayout from "@src/layouts/LandingLayout"
import VerticalLayout from "@src/layouts/VerticalLayout"
import HorizontalLayout from "@src/layouts/HorizontalLayout"
import EmptyLayout from "@customLayouts/EmptyLayout"
const ContactUs = lazy(() => import("@views/ContactUs/ContactUsJp"))
const AboutUs = lazy(() => import("@views/AboutUs/AboutUsJp"))

import LayoutWrapper from "@src/@core/layouts/components/layout-wrapper"

import seekerRoutes from "./SeekerRoutes"
import employerRoutes from "./EmployerRoutes"
import dashboardRoutes from "./DashboardRoutes"
import jobsRoutes from "./JobsRoutes"
import helpRoutes from "./HelpRoutes"

const Login = lazy(() => import("@views/Login"))
const RegisterFor = lazy(() => import("@views/Register/RegisterFor"))
const ForgotPassword = lazy(() => import("@views/ForgotPassword"))
const Error = lazy(() => import("@views/Error"))
const RedirectToImageURL = lazy(() => import("@shared/redirect/RedirectToImageURL"))

// ** Route Components
import PublicRoute from "@components/routes/PublicRoute"

// ** Utils
import { isObjEmpty } from "@utils"
import RedirectToEmailVerification from "../../views/shared/redirect/RedirectToEmailVerification"
import { withAuth } from "../../helpers/checkAuth"
import PrivacyPolicy from "../../views/PrivacyPolicy"
import Candidate from "../../views/Candidate/Candidate"
import Subscription from "@src/views/Subscription/pages/index.jsx"

// ** Init HOC
const DashboardVerticalLayoutWithAuth = withAuth(VerticalLayout)
const DashboardHorizontalLayoutWithAuth = withAuth(HorizontalLayout)

const getLayout = {
  blank: <BlankLayout />,
  vertical: <DashboardVerticalLayoutWithAuth role="superadmin" />,
  horizontal: <DashboardHorizontalLayoutWithAuth role="superadmin" />,
  landing: <LandingLayout />,
}

// ** Document title
const TemplateTitle = "%s - Keihin"

// ** Default Route
const DefaultRoute = "/employer"

// ** Merge Routes
const Routes = [
  {
    path: "/",
    index: true,
    element: <Navigate replace to={DefaultRoute} />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/candidate",
    element: <Candidate />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/subscription",
    element: <Subscription />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/seeker",
    element: <EmptyLayout />,
    meta: {
      layout: "blank",
    },
    children: seekerRoutes,
  },
  {
    path: "/employer",
    element: <EmptyLayout />,
    meta: {
      layout: "blank",
    },
    children: employerRoutes,
  },
  {
    path: "/dashboard",
    element: <EmptyLayout />,
    errorElement: <Error />,
    meta: {
      layout: "vertical",
    },
    children: dashboardRoutes,
  },
  {
    path: "/help",
    element: <EmptyLayout />,
    meta: {
      layout: "blank",
    },
    children: helpRoutes,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
    errorElement: <Error />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/about-us",
    element: <AboutUs />,
    errorElement: <Error />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
    meta: {
      layout: "blank",
    },
  },
  ...jobsRoutes,
  {
    path: "login",
    element: <Login />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "register-for",
    element: <RegisterFor />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/error",
    element: <Error />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/redirect/:action/:image",
    element: <RedirectToImageURL />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/verifyEmail",
    element: <RedirectToEmailVerification />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "*",
    element: <Error />,
    meta: {
      layout: "blank",
    },
  },
]

const getRouteMeta = (route) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta }
    } else {
      return {}
    }
  }
}

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const LayoutRoutes = []

  if (Routes) {
    Routes.filter((route) => {
      let isBlank = false
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) && defaultLayout === layout)
      ) {
        const RouteTag = PublicRoute

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === "blank" ? (isBlank = true) : (isBlank = false)
        }
        if (route.element) {
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isObjEmpty(route.element.props) && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
                LayoutWrapper
              : Fragment

          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          )
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route)
      }
      return LayoutRoutes
    })
  }
  return LayoutRoutes
}

const getRoutes = (layout) => {
  const defaultLayout = layout || "vertical"
  const layouts = ["vertical", "horizontal", "blank", "landing"]

  const AllRoutes = []

  layouts.forEach((layoutItem) => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)

    AllRoutes.push({
      path: "/",
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes,
    })
  })
  return AllRoutes
}

export { DefaultRoute, TemplateTitle, Routes, getRoutes }
