import banner1 from "@src/assets/images/banner/1.jpg"
import { Link, Outlet, useLocation } from "react-router-dom"

import {
  dashboard as navigations,
  footer as footerData,
  navbar as navbarData,
} from "@src/navigation/help"
import { Col, Container, Row, Spinner } from "reactstrap"

import Footer from "@shared/Footer"
import logo from "@src/assets/images/logo/logo.png"

import { useTranslation } from "react-i18next"
import Head from "@src/components/head"
import NavigationBar from "../shared/NavigationBar"

function Header() {
  const { t } = useTranslation()

  return (
    <>
      <div
        className="wt-bnr-inr overlay-wraper bg-center"
        style={{ backgroundImage: `url(${banner1})` }}
      >
        <div className="overlay-main site-bg-white opacity-01"></div>
        <Container>
          <div className="wt-bnr-inr-entry">
            <div className="banner-title-outer">
              <div className="banner-title-name">
                <h2 className="wt-title">{t("empProfile")}</h2>
              </div>
            </div>

            <div>
              <ul className="wt-breadcrumb breadcrumb-style-2">
                <li>
                  <Link className="text-primary nav-link" to={"/employer"}>
                    {t("home")}
                  </Link>
                </li>
                <li>{t("empProfile")}</li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

function Sidebar() {
  const location = useLocation()
  const { t } = useTranslation()
  const isActive = (link) => {
    return location.pathname === link ? "active" : ""
  }

  return (
    <div className="twm-nav-list-1">
      <ul>
        {navigations.length > 0
          ? navigations.map((val) => {
              return (
                <li key={val.navLink} className={isActive(val.navLink)}>
                  <Link to={val.navLink}>
                    {<div className=" px-1">{val.icon}</div>} {t(val.title)}
                  </Link>
                </li>
              )
            })
          : null}
      </ul>
    </div>
  )
}

function Dashboard() {
  return (
    <div className="page-content">
      <Head title="Help - Mahajob" />
      <NavigationBar isNew={true} bgColor="primary" navigations={navbarData} page="employer" />
      <div className="section-full p-t100 p-b90 site-bg-white">
        <Container fluid>
          <Row sm={1}>
            <Col lg={3} className="rightSidebar m-b30">
              <Sidebar />
            </Col>

            <Col lg={9} className="m-b30">
              <div className="twm-right-section-panel site-bg-gray">
                <Outlet />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer logo={logo} navigations={footerData()} />
    </div>
  )
}

export default Dashboard
