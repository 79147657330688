import React from "react"
import { Link } from "react-router-dom"

import logo from "@src/assets/images/logo/logo-new.png"
import { useTranslation } from "react-i18next"
import { Col, Container, List, Row } from "reactstrap"
import { useWindowDimensions } from "@src/utility/hooks/useWindowDimensions"
import GooglePlaySvg from "@src/assets/images/icons/google-play.svg"

const Footer = ({ navigations }) => {
  const { width } = useWindowDimensions()
  const isMobile = width <= 834
  return (
    <footer className="footer-light">
      <div className={`${isMobile ? "p-a15" : "custom-container"}`}>
        <div className="footer-top">
          <Row md={1}>
            <Col lg={4}>
              <WidgetAbout logo={logo} />
            </Col>
            {isMobile ? (
              <>
                <Col lg={2}>
                  <Row lg={1} md={3} sm={3}>
                    {navigations?.length > 0
                      ? navigations?.map((item) => {
                          if (item.id === 3) {
                            return (
                              <Col key={item.title} style={{ whiteSpace: "nowrap" }}>
                                <Sosmed data={item} />
                              </Col>
                            )
                          } else {
                            return null
                          }
                        })
                      : null}
                  </Row>
                </Col>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                    gap: "10px",
                  }}
                >
                  <Col lg={2}>
                    <Row lg={1} md={3} sm={3}>
                      {navigations.length > 0
                        ? navigations.map((item) => {
                            if (item.id === 0) {
                              return (
                                <Col key={item.title} style={{ whiteSpace: "nowrap" }}>
                                  <WidgetServices data={item} />
                                </Col>
                              )
                            } else {
                              return null
                            }
                          })
                        : null}
                    </Row>
                  </Col>
                  <Col lg={2}>
                    <Row lg={1} md={3} sm={3}>
                      {navigations.length > 0
                        ? navigations.map((item) => {
                            if (item.id === 1) {
                              return (
                                <Col key={item.title} style={{ whiteSpace: "nowrap" }}>
                                  <WidgetServices data={item} />
                                </Col>
                              )
                            } else {
                              return null
                            }
                          })
                        : null}
                    </Row>
                  </Col>

                  <Col lg={2}>
                    <Row lg={1} md={3} sm={3}>
                      {navigations.length > 0
                        ? navigations.map((item) => {
                            if (item.id === 2) {
                              return (
                                <Col key={item.title} style={{ whiteSpace: "nowrap" }}>
                                  <WidgetServices data={item} />
                                </Col>
                              )
                            } else {
                              return null
                            }
                          })
                        : null}
                    </Row>
                  </Col>
                  <Col lg={2}>
                    <Row lg={1} md={3} sm={3}>
                      <WidgetPlayStore isMobile />
                    </Row>
                  </Col>
                </div>{" "}
              </>
            ) : (
              <>
                <Col lg={2}>
                  <Row lg={1} md={3} sm={3}>
                    {navigations.length > 0
                      ? navigations.map((item) => {
                          if (item.id === 0) {
                            return (
                              <Col key={item.title} style={{ whiteSpace: "nowrap" }}>
                                <WidgetServices data={item} />
                              </Col>
                            )
                          } else {
                            return null
                          }
                        })
                      : null}
                  </Row>
                </Col>
                <Col lg={2}>
                  <Row lg={1} md={3} sm={3}>
                    {navigations.length > 0
                      ? navigations.map((item) => {
                          if (item.id === 1) {
                            return (
                              <Col key={item.title} style={{ whiteSpace: "nowrap" }}>
                                <WidgetServices data={item} />
                              </Col>
                            )
                          } else {
                            return null
                          }
                        })
                      : null}
                  </Row>
                </Col>
                <Col lg={2}>
                  <Row lg={1} md={3} sm={3}>
                    {navigations.length > 0
                      ? navigations.map((item) => {
                          if (item.id === 2) {
                            return (
                              <Col key={item.title} style={{ whiteSpace: "nowrap" }}>
                                <WidgetServices data={item} />
                              </Col>
                            )
                          } else {
                            return null
                          }
                        })
                      : null}
                  </Row>
                </Col>
                <Col lg={2}>
                  <Row lg={1} md={3} sm={3}>
                    {navigations.length > 0
                      ? navigations.map((item) => {
                          if (item.id === 3) {
                            return (
                              <Col key={item.title} style={{ whiteSpace: "nowrap" }}>
                                <Sosmed withTitle={true} data={item} />
                              </Col>
                            )
                          } else {
                            return null
                          }
                        })
                      : null}
                    <WidgetPlayStore />
                  </Row>
                </Col>
              </>
            )}
          </Row>
        </div>
        <div className="footer-bottom">
          <FooterBottomInfo />
        </div>
      </div>
    </footer>
  )
}

const WidgetPlayStore = ({ isMobile }) => {
  const { t } = useTranslation()
  return (
    <div className={!isMobile && "widget widget_services ml-2"}>
      <h3 className="widget-title w-full">{t("Download App")}</h3>
      <Row>
        <Col className="relative">
          <Link to="https://play.google.com/store/apps/details?id=com.mahajob.v2" target="_blank">
            <img src={GooglePlaySvg} alt="Google Play" className={"absolute h-28"} />
          </Link>
        </Col>
      </Row>
    </div>
  )
}

const WidgetAbout = ({ logo }) => {
  const { t } = useTranslation()
  return (
    <div className="widget widget_about">
      <div className="logo-footer clearfix">
        <a href="/">
          <img src={logo} alt="" className="w-150" />
        </a>
      </div>
      <p>
        Maha-Job by Keihin Group Japan <br /> {t("(5-27-18-202 Nishikamata Ota-ku,Tokyo)")}
      </p>
    </div>
  )
}

const WidgetServices = ({ data }) => {
  const { t } = useTranslation()
  return (
    <div className="widget widget_services ftr-list-center">
      <h3 className="widget-title">{t(data.title)}</h3>
      <List type="unstyled">
        {data.children.length > 0
          ? data.children.map((item) => {
              return (
                <li key={item.title}>
                  <Link style={{ fontWeight: 500 }} to={item.url}>
                    {t(item.title)}
                  </Link>
                </li>
              )
            })
          : nul}
      </List>
    </div>
  )
}

const Sosmed = ({ data, withTitle }) => {
  const { t } = useTranslation()

  return (
    <div className="widget widget_services ftr-list-center">
      {withTitle && <h3 className="widget-title">{t(data.title)}</h3>}
      <Row style={{ whiteSpace: "nowrap" }}>
        {data.children.map((item, index) => (
          <Col key={index} className="mr-2">
            <Link to={item.url}>
              <img
                src={item.title.props.src}
                alt={item.title.props.alt}
                style={{ width: "24px" }}
              />
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  )
}

const FooterBottomInfo = () => {
  return (
    <div className="footer-bottom-info">
      <div className="footer-copy-right">
        <span className="copyrights-text">Copyright © 2024 Maha-Job All Rights Reserved </span>
      </div>
      <div className="footer-copy-right">
        <a className="copyrights-text" href="/privacy-policy">
          Privacy Policy
        </a>
      </div>
    </div>
  )
}

export default Footer
