import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { authHeader, baseUrlConfig } from "../../helpers/http"

const baseurl = "job/industries"

export const jobIndustriesApi = createApi({
  reducerPath: "job/industries",
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ["JobIndustries"],
  endpoints: (builder) => ({
    getJobIndustries: builder.mutation({
      query: (params) => ({
        url: `${baseurl}`,
        params,
        method: "get",
      }),
      providesTags: ["GetJobIndustries"],
      transformResponse: (response) => response.results,
    }),
    getJobIndustryBySlug: builder.mutation({
      query: (slug) => ({
        url: `${baseurl}/${slug}`,
        method: "get",
      }),
      providesTags: ["GetJobIndustry"],
      transformResponse: (response) => response.results,
    }),
    postJobIndustries: builder.mutation({
      query: (body) => ({
        url: `${baseurl}`,
        method: "post",
        headers: authHeader(),
        body,
      }),
      providesTags: ["PostJobIndustries"],
      transformResponse: (response) => response.results,
    }),
    updateJobIndustry: builder.mutation({
      query(data) {
        const { id, ...body } = data
        return {
          url: `${baseurl}/${id}`,
          method: "put",
          headers: authHeader(),
          body,
        }
      },
      providesTags: ["UpdateJobIndustry"],
      transformResponse: (response) => response.results,
    }),
    deleteJobIndustry: builder.mutation({
      query: ({ id }) => ({
        url: `${baseurl}/${id}`,
        method: "delete",
        headers: authHeader(),
      }),
      providesTags: ["DeleteJobIndustry"],
      transformResponse: (response) => response.results,
    }),
    getJobIndustriesEmployeer: builder.mutation({
      query: (params) => ({
        url: `employer${baseurl}`,
        params,
        method: "get",
      }),
      providesTags: ["GetJobIndustries"],
      transformResponse: (response) => response.results,
    }),
  }),
})

export const {
  useGetJobIndustriesMutation,
  useGetJobIndustryBySlugMutation,
  usePostJobIndustriesMutation,
  useUpdateJobIndustryMutation,
  useDeleteJobIndustryMutation,
  useGetJobIndustriesEmployeerMutation,
} = jobIndustriesApi
