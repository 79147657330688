import { lazy } from "react"
import { Navigate } from "react-router-dom"

import SeekerLayout from "@views/Seeker/Dashboard"
// import { withAuth } from "../../helpers/checkAuth"

const LandingSeeker = lazy(() => import("@views/Seeker/Landing"))
const Register = lazy(() => import("@views/Seeker/Register"))

const Profile = lazy(() => import("@views/Seeker/Dashboard/Profile"))
const AppliedJobs = lazy(() => import("@views/Seeker/Dashboard/AppliedJobs"))
const SavedJobs = lazy(() => import("@views/Seeker/Dashboard/SavedJobs"))
const Resume = lazy(() => import("@views/Seeker/Dashboard/Resume"))
const CVManager = lazy(() => import("@views/Seeker/Dashboard/FileManager"))
const JobAlerts = lazy(() => import("@views/Seeker/Dashboard/JobAlerts"))
const ChangePassword = lazy(() => import("@views/Seeker/Dashboard/ChangePassword"))
const ContactUs = lazy(() => import("@views/ContactUs/ContactUsEn"))
const AboutUs = lazy(() => import("@views/AboutUs/AboutUsEn"))
const Testimonials = lazy(() => import("@views/Testimonials/index"))
const SeekerCart = lazy(() => import("@views/Seeker/Cart/index"))
const SeekerSubscribe = lazy(() => import("@src/views/Seeker/Dashboard/Subcribe/index"))
const SeekerOrderStatus = lazy(() => import("@src/views/Seeker/Dashboard/Subcribe/OrderStatus"))
const JobPreferences = lazy(() => import("@src/views/Seeker/Dashboard/JobPreferences"))
const DeleteAccount = lazy(() => import("@src/views/Seeker/Dashboard/DeleteAccount"))

// const DashboardSeekerLayout = withAuth(SeekerLayout)
const DashboardSeekerLayout = SeekerLayout

export default [
  {
    path: "",
    element: <LandingSeeker />,
  },
  {
    path: "about-us",
    element: <AboutUs />,
  },
  {
    path: "testimonials",
    element: <Testimonials />,
  },
  {
    path: "contact-us",
    element: <ContactUs />,
  },
  {
    path: "login",
    element: <Navigate to="/login" />,
  },
  {
    path: "register",
    meta: {
      layout: "protected",
    },
    element: <Register />,
  },
  {
    path: "/seeker/cart",
    meta: {
      layout: "protected",
    },
    element: <SeekerCart />,
  },
  {
    path: "order-status/:id",
    meta: {
      layout: "protected",
    },
    element: <SeekerOrderStatus />,
  },
  {
    path: "dashboard",
    element: <DashboardSeekerLayout role="seeker" />,
    children: [
      {
        path: "",
        element: <Navigate to={"/seeker/dashboard/profile"} />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "subscribe",
        element: <SeekerSubscribe />,
      },
      {
        path: "job-preferences",
        element: <JobPreferences />,
      },
      {
        path: "applied-jobs",
        element: <AppliedJobs />,
      },
      {
        path: "saved-jobs",
        element: <SavedJobs />,
      },
      {
        path: "resume",
        element: <Resume />,
      },
      {
        path: "file-manager",
        element: <CVManager />,
      },
      {
        path: "job-alerts",
        element: <JobAlerts />,
      },
      {
        path: "change-password",
        element: <ChangePassword />,
      },
      {
        path: "delete-account",
        element: <DeleteAccount />,
      },
    ],
  },
]
