import { useWindowDimensions } from "@src/utility/hooks/useWindowDimensions"
import photo from "@src/assets/images/employer/candidates/img.png"
import Tower from "@src/assets/icons/tower.svg"
import constants from "@src/utility/constants"
import { useTranslation } from "react-i18next"
import { nameTranslate } from "@src/utility/Utils"
import { useMemo } from "react"

const CardCandidate = ({ image, item, onClick = () => {}, qualificationData }) => {
  const { width: widthScreen } = useWindowDimensions()
  const isLarge = widthScreen >= 1500
  const isMobile = widthScreen <= 1024
  const { t } = useTranslation()
  const qualification = item?.seekerProfile?.qualification

  const dataSeekerSkillList = useMemo(() => {
    let data = ""
    item?.seekerProfile?.seekerSkill?.[0]?.certificates?.forEach((item, idx) => {
      if (idx < 2) {
        data += `${nameTranslate(item.name, item.nameEn, item.nameID)}, `
      }
    })
    return data
  }, [item])

  const dataSeekerQualification = useMemo(() => {
    if (qualificationData && item) {
      return qualificationData?.find((item) => item.value === qualification)?.label
    }
  }, [item, qualificationData])

  return (
    <div
      onClick={onClick}
      style={{
        border: "1px solid #E9ECEF",
        width: isMobile ? "100%" : isLarge ? 240 : 213,
      }}
      className={`bg-white radius-md p-1 card-candidates`}
    >
      <div style={{ position: "relative" }} className="">
        <div
          style={{
            fontSize: 8,
            position: "absolute",
            bottom: 8,
            borderRadius: 4,
            left: 8,
            color: "#fff",
            background: "#92388E",
            padding: "2px 4px 2px 4px",
          }}
          className=""
        >
          {dataSeekerQualification || "-"}
        </div>
        <img
          className="img-profile"
          style={{
            borderRadius: 8,
            height: isLarge ? 130 : 163,
            width: "100%",
            objectFit: "contain",
          }}
          src={image ? `${constants.apiImageResourceURL}${image}` : photo}
          alt="Photo Candidates"
        />
      </div>
      <div className="flex item-start flex-col gap-y-2 mt-1">
        <h5 style={{ fontSize: 14, fontWeight: "bold" }} className="">{`${item?.firstName || "-"} ${
          item?.lastName || "-"
        } `}</h5>
        <div>
          <div className="flex gap-x-2">
            <img src={Tower} alt="tower" />
            <div style={{ fontSize: 12 }} className="text-gray-400">
              {dataSeekerSkillList || "-"}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="text-end text-sm text-pink-500 align-content-center cursor-pointer">
          {t("View Profile")} {">"}
        </div>
      </div>
    </div>
  )
}

export default CardCandidate
