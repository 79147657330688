import React, { useEffect, useMemo } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useGetBestCandidatesMutation } from "@src/redux/employer/candidate"
import NoData from "./NoData"
import CardCandidate from "./CardCandidate"
import { useWindowDimensions } from "@src/utility/hooks/useWindowDimensions"
import { Autoplay } from "swiper"
import { Col, Row } from "reactstrap"
import { useGetJobCategoriesMutation } from "@src/redux/job/job_categories"
import { nameTranslate } from "@src/utility/Utils"

const SingleCandidate = () => {
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const isMobile = width <= 1024

  const [getBestCandidates, { data = [], isLoading, isSuccess, reset }] =
    useGetBestCandidatesMutation()

  const [getQualification, { data: dataGetQualification }] = useGetJobCategoriesMutation()

  const qualificationOptions = useMemo(() => {
    return (
      dataGetQualification?.map((item) => {
        return {
          value: item.id,
          label: nameTranslate(item.name, item.nameEn, item.nameID),
        }
      }) ?? []
    )
  }, [dataGetQualification])

  useEffect(() => {
    // getJobSeeker()
    getBestCandidates()
    getQualification()
    return () => reset()
  }, [])

  const navigate = useNavigate()

  const goDetail = (seekerId) => {
    navigate(`/login`, { state: { seekerId } })
  }

  return (
    <div className={`${isMobile ? "w-100" : "w-18"}`}>
      <Row sm={1} className="align-items-center">
        <span className="text-md font-bold ">{t("Recomended Candidate")}</span>
      </Row>
      <div className="mx-auto">
        <Swiper
          className="py-1"
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
          }}
          slidesPerView={1}
          modules={[Autoplay]}
        >
          {!isLoading && data?.length === 0 && <NoData isSmall />}
          {isLoading && <div className="text-center">Please wait . . .</div>}
          {isSuccess &&
            data?.map((item, index) => (
              <SwiperSlide key={index}>
                <CardCandidate
                  qualificationData={qualificationOptions}
                  onClick={() => goDetail(item?.id)}
                  item={item}
                  image={item?.seekerProfile?.photo}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  )
}

export default SingleCandidate
