import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { authHeader, baseUrlConfig } from "../../helpers/http"

const baseurl = "job/categories"

export const jobCategoriesApi = createApi({
  reducerPath: "job/categories",
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ["JobCategories"],
  endpoints: (builder) => ({
    getJobCategories: builder.mutation({
      query: (params) => ({
        url: `${baseurl}`,
        params,
        method: "get",
      }),
      providesTags: ["GetJobCategories"],
      transformResponse: (response) => response.results,
    }),
    getJobCategoryBySlug: builder.mutation({
      query: (slug) => ({
        url: `${baseurl}/${slug}`,
        method: "get",
      }),
      providesTags: ["GetJobCategory"],
      transformResponse: (response) => response.results,
    }),
    postJobCategory: builder.mutation({
      query: (body) => ({
        url: `${baseurl}`,
        method: "post",
        headers: authHeader(),
        body,
      }),
      providesTags: ["PostJobCategory"],
      transformResponse: (response) => response.results,
    }),
    updateJobCategory: builder.mutation({
      query(data) {
        const { id, ...body } = data
        return {
          url: `${baseurl}/${id}`,
          method: "put",
          headers: authHeader(),
          body,
        }
      },
      providesTags: ["UpdateJobCategory"],
      transformResponse: (response) => response.results,
    }),
    deleteJobCategory: builder.mutation({
      query: ({ id }) => ({
        url: `${baseurl}/${id}`,
        method: "delete",
        headers: authHeader(),
      }),
      providesTags: ["DeleteJobCategory"],
      transformResponse: (response) => response.results,
    }),
  }),
})

export const {
  useGetJobCategoriesMutation,
  useGetJobCategoryBySlugMutation,
  usePostJobCategoryMutation,
  useUpdateJobCategoryMutation,
  useDeleteJobCategoryMutation,
} = jobCategoriesApi
