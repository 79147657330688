import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { authHeader, baseUrlConfig } from "../../helpers/http"

export const seekerAuthApi = createApi({
  reducerPath: "seeker/auth",
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ["Seekers"],
  endpoints: (builder) => ({
    seekerLogin: builder.mutation({
      query: (body) => ({
        url: "seeker/auth/login",
        method: "post",
        headers: authHeader(),
        body,
      }),
      providesTags: ["Seekers"],
      transformResponse: (response) => response.results,
      transformErrorResponse: (response) => response.status,
    }),
    seekerRegister: builder.mutation({
      query: (body) => ({
        url: "seeker/auth/newRegister",
        method: "post",
        headers: authHeader(),
        body,
      }),
      providesTags: ["Seekers"],
      transformResponse: (response) => response.results,
      transformErrorResponse: (response) => response.data,
    }),
    //querybypass
    seekerChangePassword: builder.mutation({
      query: (body) => ({
        url: "seeker/auth/changePassword",
        method: "post",
        headers: authHeader(),
        params: body,
      }),
      providesTags: ["SeekerChangePassword"],
      transformResponse: (response) => response.results,
      transformErrorResponse: (response) => response.status,
    }),
    seekerVerifyEmail: builder.mutation({
      query: (code) => ({
        url: "seeker/auth/verifyEmail",
        method: "post",
        headers: authHeader(),
        params: { code },
      }),
      providesTags: ["SeekerChangePassword"],
      transformResponse: (response) => response.results,
      transformErrorResponse: (response) => response.status,
    }),
    deleteAccount: builder.mutation({
      query: (body) => ({
        url: "seeker/auth/deleteUser",
        method: "delete",
        headers: authHeader(),
        body,
      }),
    }),
  }),
})

export const {
  useSeekerLoginMutation,
  useSeekerRegisterMutation,
  useSeekerChangePasswordMutation,
  useSeekerVerifyEmailMutation,
  useDeleteAccountMutation,
} = seekerAuthApi
