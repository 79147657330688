import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { authHeader, baseUrlConfig } from "../../helpers/http"

const baseurl = "seeker/qualification"

export const seekerQualificationApi = createApi({
  reducerPath: "seeker/qualification",
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ["SeekerEducation"],
  endpoints: (builder) => ({
    getQualification: builder.mutation({
      query: () => ({
        url: `${baseurl}`,
        method: "GET",
        headers: authHeader(),
      }),
      providesTags: ["GetAllSeekerQualification"],
      transformResponse: (response) => response.results,
    }),
    getQualificationById: builder.mutation({
      query: (id) => ({
        url: `${baseurl}/${id}`,
        method: "GET",
        headers: authHeader(),
      }),
      providesTags: ["GetSingleSeekerQualification"],
      transformResponse: (response) => response.results,
    }),
    postQualification: builder.mutation({
      query: (body) => ({
        url: `${baseurl}`,
        method: "POST",
        headers: authHeader(),
        body,
      }),
      providesTags: ["PostSingleSeekerQualification"],
      transformResponse: (response) => response.results,
    }),
    updateQualification: builder.mutation({
      query: (data) => ({
        url: `${baseurl}/${data.id}`,
        method: "PUT",
        headers: authHeader(),
        body: data.body,
      }),
      providesTags: ["UpdateSingleSeekerQualification"],
      transformResponse: (response) => response.results,
    }),
    deleteQualification: builder.mutation({
      query: (id) => {
        console.log(`${baseurl}/${id}`)
        return {
          url: `${baseurl}/${id}`,
          method: "DELETE",
          headers: authHeader(),
        }
      },
      providesTags: ["DeleteSingleSeekerQualification"],
      transformResponse: (response) => response.results,
    }),
    getAllQualificationCertification: builder.query({
      query: () => ({
        url: `seeker/jobs/seeker-certificate`,
        method: "GET",
        headers: authHeader(),
      }),
      transformResponse: (response) => response?.results,
    }),
    getAllSkills: builder.query({
      query: () => ({
        url: `seeker/skill`,
        method: "GET",
        headers: authHeader(),
      }),
      transformResponse: (response) => response?.results,
    }),
    postSkill: builder.mutation({
      query: (body) => ({
        url: `seeker/skill`,
        method: "post",
        body,
        headers: authHeader(),
      }),
      transformResponse: (response) => response?.results,
    }),
    putSkill: builder.mutation({
      query: ({ id, data }) => ({
        url: `seeker/skill/${id}`,
        method: "put",
        body: { certificateIds: data.certificateIds },
        headers: authHeader(),
      }),
      transformResponse: (response) => response?.results,
    }),
    deleteSkill: builder.mutation({
      query: (id) => ({
        url: `seeker/skill/${id}`,
        method: "delete",
        headers: authHeader(),
      }),
      transformResponse: (response) => response?.results,
    }),
  }),
})

export const {
  useGetQualificationMutation,
  useGetQualificationByIdMutation,
  usePostQualificationMutation,
  useUpdateQualificationMutation,
  useDeleteQualificationMutation,
  useGetAllQualificationCertificationQuery,
  useGetAllSkillsQuery,
  usePostSkillMutation,
  usePutSkillMutation,
  useDeleteSkillMutation,
} = seekerQualificationApi
