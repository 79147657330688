import React, { useState } from 'react'
import minus from '@src/assets/icons/minus.svg'
import plus from '@src/assets/icons/plus.svg'
import { useWindowDimensions } from '@src/utility/hooks/useWindowDimensions'
import { useTranslation } from 'react-i18next'

const QItem = ({ questions = "", answer = "" }) => {
    const [isOpen, setIsOpen] = useState(false)
    const {t} = useTranslation()
    return (
        <div style={{
            borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
            borderBottomColor: "#E9ECEF",
            paddingBottom: "12px"
        }} >
            <div className={`flex items-center justify-between`}>
                <div onClick={() => {
                    setIsOpen(!isOpen)
                }} className='cursor-pointer text-md font-bold'>{t(`${questions}`)}</div>
                <img onClick={() => {
                    setIsOpen(!isOpen)
                }} className='cursor-pointer' src={isOpen ? minus : plus} alt='plus-minus' />
            </div>
            {
                isOpen && (
                    <div className={`w-75 font-light ${isOpen ? "open-qitem" : "close-qitem"}`}>{t(`${answer}`)}</div>
                )
            }
        </div>
    )

}

const Questions = () => {
    const { width } = useWindowDimensions()
    const {t} = useTranslation()
    const isMobile = width <= 1024
    const dataQuestions = [
        {
            question: "Q: How can I post job openings on your job site?",
            answer: "A: To post job openings, you need to register as a member on Mahajob and fill out the posting application form with the necessary information.",
        },
        {
            question: "Q: Is there a fee for posting job openings?",
            answer: "A: With the exception of some optional features, posting job openings is generally free of charge.",
        },
        {
            question: "Q: How can I edit or delete a posted job opening?",
            answer: "A: Log in, select the posted job opening, and make changes from the screen where editing and deletion are possible.",
        },
        {
            question: "Q: How are job applicants selected?",
            answer: "A: Based on the applicant's resume and work experience, we conduct a selection process and arrange interviews.",
        },
        {
            question: "Q: What are the benefits for companies using your job site?",
            answer: "A: By using our job site, companies can communicate job information to a wide range of job seekers. In addition, they can freely choose the posting period and method, which leads to streamlining their hiring activities.",
        }
    ]
    return (
        <div className={`page-wrapper bg-gray-200 mb-2 mt-2`}>
            <div className={`${isMobile && 'flex-col'} custom-container flex items-start jutify-center gap-x-8`}>
                <div className={`font-bold text-lg ${isMobile ? "w-full align-center" : "w-13"}`}>
                    {t("Have Questions? Get Answers Here")} 
                </div>
                <div className={`radius-md bg-white p-2 ${isMobile ? 'w-full' : 'w-75'} flex flex-col gap-y-4`}>
                    {
                        dataQuestions.map((item, index) => (
                            <QItem key={index} questions={item.question} answer={item.answer} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Questions