import banner1 from "@src/assets/images/banner/1.jpg"
import pic1 from "@src/assets/images/no-photo.jpg"
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom"

import Footer from "@shared/Footer"
import NavigationBar from "@shared/NavigationBar"

import logo from "@src/assets/images/logo/logo.png"
import {
  dashboard as navigations,
  footer as footerData,
  navbar as navNavigations,
} from "@src/navigation/landing-seeker"
import { Alert, Col, Container, Row, Spinner } from "reactstrap"
import { logout } from "../../../helpers/localstorage"
import {
  useGetQueryProfileQuery,
  useGetViewCvMutation,
  useUpdateProfileMutation,
} from "../../../redux/seeker/profile"
import constants from "../../../utility/constants"

import ModalAlert from "@shared/modals/ModalAlert"
import { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { protectPage } from "@src/helpers/checkAuth"
import Error from "@src/views/Error"
import "./index.scss"
import toast from "react-hot-toast"
import Head from "@src/components/head"

function Header() {
  const currentUrl = useLocation().pathname.split("/")
  return (
    <>
      <div
        className="wt-bnr-inr overlay-wraper bg-center p-0"
        style={{ backgroundImage: `url(${banner1})` }}
      >
        {/* <div className="overlay-main site-bg-white opacity-01 p-0"></div> */}
        <div className="container">
          <div className="wt-bnr-inr-entry">
            <div className="banner-title-outer">
              <div className="banner-title-name">
                <h2 className="wt-title" style={{ width: "256px" }}>
                  Seeker Dashboard
                </h2>
              </div>
            </div>

            <div className="text-center">
              <ul className="wt-breadcrumb breadcrumb-style-2">
                <li>
                  <Link to={"/"} className="text-primary nav-link">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to={currentUrl[currentUrl.length - 1]}>
                    {currentUrl[currentUrl.length - 1]}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function Sidebar() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const profile = JSON.parse(localStorage.getItem("seekerProfile"))

  const { data, isLoading, isError, error, refetch } = useGetQueryProfileQuery()
  const [updateProfile, updateProfileResult] = useUpdateProfileMutation()
  const isActive = (link) => {
    return location.pathname === link ? "active" : ""
  }

  const [getViewCv] = useGetViewCvMutation()

  const handleViewCv = async () => {
    getViewCv(profile.userId).then((res) => {
      const newWindow = window.open()
      newWindow.document.write(res.data)
    })
  }

  const handleLogout = useCallback(() => {
    logout()
    navigate("/login", { replace: true })
  }, [navigate])

  const updatePhoto = (e) => {
    const file = e.target.files[0]
    if (file.size > 1000000) {
      return toast.error("File size must be less than 1MB")
    }

    if (file) {
      const form = new FormData()
      form.append("photo", file)
      updateProfile(form)
    }
  }

  useEffect(() => {
    if (updateProfileResult.isSuccess) {
      refetch()
    }
  }, [updateProfileResult])

  const NavLinkRender = ({ navLink, title, icon }) => {
    if (String(navLink).includes("View CV")) {
      return (
        <a href="#" type="button" onClick={handleViewCv}>
          {icon} View CV
        </a>
      )
    } else if (String(navLink).includes("logout")) {
      return (
        <a href="#" type="button" onClick={handleLogout}>
          {icon} Logout
        </a>
      )
    } else {
      return (
        <Link to={navLink}>
          {icon} {t(title)}
        </Link>
      )
    }
  }

  return (
    <>
      <div className="side-bar-st-1">
        {error ? (
          <Alert color="danger" className="px-2 py-1">
            <p>Error: {error?.data?.message ?? "Something went wrong"}</p>
          </Alert>
        ) : isLoading ? (
          <p>...Loading</p>
        ) : data ? (
          <>
            <div className="twm-candidate-profile-pic">
              {updateProfileResult.isLoading ? (
                <Spinner size={"sm"} />
              ) : (
                <>
                  <img
                    src={data.photo ? `${constants.apiImageResourceURL}${data.photo}` : pic1}
                    alt="Profile Pic"
                  />
                  <div className="upload-btn-wrapper">
                    <button className="site-button button-sm">
                      {data.photo ? "Change photo" : "Upload photo"}
                    </button>
                    <input
                      type="file"
                      name="photo"
                      accept=".png, .jpg, .jpeg"
                      onChange={updatePhoto}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="twm-mid-content text-center">
              <a href="candidate-detail.html" className="twm-job-title">
                <h4>
                  {data.user?.firstName ?? ""} {data.user?.lastName ?? ""}
                </h4>
              </a>
            </div>
          </>
        ) : null}

        <div className="twm-nav-list-1">
          <ul>
            {navigations.length > 0
              ? navigations.map((val) => {
                  return (
                    <li key={val.navLink} className={isActive(val.navLink)}>
                      <NavLinkRender {...val} />
                    </li>
                  )
                })
              : null}
          </ul>
        </div>
      </div>
      <ModalAlert isOpen={isError} title="You're not logged" onClick={handleLogout} closeText="Ok">
        {error?.data?.message ?? "Something went wrong"}
      </ModalAlert>
    </>
  )
}

function Dashboard() {
  // TODO: protect page when wrong role
  if (!protectPage({ currentPath: "seeker" })) {
    return <Error />
  }
  return (
    <>
      <Head title="Seeker - Mahajob" />
      <NavigationBar isNew={true} navigations={navNavigations} page="seeker" />
      <div className="page-content">
        {/* <Header /> */}
        <div className="section-full p-t120  p-b90 site-bg-white">
          <Container fluid>
            <Row sm={1}>
              <Col xl={3} lg={4} className="rightSidebar m-b30">
                <Sidebar />
              </Col>

              <Col xl={9} lg={8} className="m-b30">
                <div className="twm-right-section-panel site-bg-gray">
                  <Outlet />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer logo={logo} navigations={footerData} />
      </div>
    </>
  )
}

export default Dashboard
